.flex-container {
	display: flex;
	flex-direction: row;
	font-size: 30px;
	text-align: center;
}

.flex-item-left {
	padding: 10px;
	flex: 70%;
}

.flex-item-right {
	padding: 10px;
	flex: 30%;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
	.flex-container {
		flex-direction: column;
	}
}
